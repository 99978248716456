<!--
 * @Description:
 * @Autor: Zhongyu
 * @Date: 2021-08-30 20:51:42
 * @LastEditors: Zhongyu
 * @LastEditTime: 2021-09-26 15:23:52
-->
<template>
  <div class="personal-center-menus">
    <el-menu
      :default-active="activeIndex"
      :default-openeds="defaultOpeneds"
      @select="handleSelect"
    >
      <template v-for="(item, i) in menus">
        <el-submenu
          v-if="item.children && item.children.length > 0"
          :key="i"
          :index="item.key"
        >
          <template slot="title">
            <span :class="item.icon"></span>
            <span>{{ item.title }}</span>
          </template>
          <el-menu-item
            v-for="(children, j) in item.children"
            :key="j"
            :index="children.key"
          >
            {{ children.title }}
          </el-menu-item>
        </el-submenu>
        <el-menu-item
          v-else
          :key="i"
          :index="item.key"
          :style="{ width: `${item.title.length * 16 + 40}px` }"
        >
          <span :class="item.icon"></span>
          {{ item.title }}
        </el-menu-item>
      </template>
    </el-menu>
  </div>
</template>

<script>
export default {
  props: {
    // active: {
    //   type: Object,
    //   default: () => {
    //     return {};
    //   }
    // }
  },
  data() {
    return {
      activeIndex: "1-1",
      defaultOpeneds: ["1", "3", "4", "5"],
      menus: [
        {
          key: "1",
          icon: "iconfont icon-gerenxinxi",
          title: "个人信息",
          url: "/personal-center/user",
          children: [
            {
              key: "1-1",
              title: "我的资料",
              url: "/personal-center/user/info"
            },
            {
              key: "1-2",
              title: "更改密码",
              url: "/personal-center/user/pass"
            }
          ]
        },
        {
          key: "2",
          icon: "iconfont icon-tongji-01",
          title: "收益统计",
          url: "/personal-center/earnings"
        },
        {
          key: "6",
          icon: "iconfont icon-tongji-01",
          title: "保证金",
          url: "/personal-center/cashDeposit"
        },
        {
          key: "3",
          icon: "iconfont icon-yue",
          title: "余额",
          url: "/personal-center/balance",
          children: [
            {
              key: "3-1",
              title: "钱包余额",
              url: "/personal-center/balance/wallet"
            },
            {
              key: "3-2",
              title: "采购结余",
              url: "/personal-center/balance/purchase"
            }
          ]
        },
        // balance/purchase
        {
          key: "4",
          icon: "iconfont icon-anzhuang",
          title: "安装统计",
          url: "/personal-center/install",
          children: [
            {
              key: "4-1",
              title: "订单数据统计",
              url: "/personal-center/install/statistical"
            },
            {
              key: "4-2",
              title: "待签收",
              url: "/personal-center/install/signit"
            },
            {
              key: "4-3",
              title: "服务中",
              url: "/personal-center/install/inservice"
            },
            {
              key: "4-4",
              title: "已预约",
              url: "/personal-center/install/appointment"
            },
            {
              key: "4-5",
              title: "已完成",
              url: "/personal-center/install/complete"
            },
            {
              key: "4-6",
              title: "订单评价",
              url: "/personal-center/install/evaluation"
            },
            {
              key: "4-7",
              title: "已结算",
              url: "/personal-center/install/settlement"
            },
            {
              key: "4-8",
              title: "返单",
              url: "/personal-center/install/reorder"
            }
          ]
        },
        {
          key: "5",
          icon: "iconfont icon-dingdan",
          title: "采购单",
          url: "/personal-center/purchase",
          children: [
            {
              key: "5-1",
              title: "已下单",
              url: "/personal-center/purchase/place-order"
            },
            {
              key: "5-2",
              title: "已发货",
              url: "/personal-center/purchase/shipped"
            },
            {
              key: "5-3",
              title: "已完成",
              url: "/personal-center/purchase/completed"
            },
            {
              key: "5-4",
              title: "退货",
              url: "/personal-center/purchase/refund"
            },
            {
              key: "5-5",
              title: "收货地址",
              url: "/personal-center/purchase/shipping-address"
            }
          ]
        }
      ]
    };
  },
  watch: {
    $route: {
      handler(val) {
        let path = val.path;

        let key = "",
          keyPath = [];
        let menus = this.menus;
        let menu = menus.find(item => {
          return path.indexOf(item.url) != -1;
        });
        if (menu) {
          key = menu.key;
          keyPath.push(menu.key);
          if (menu.children && menu.children.length > 0) {
            let children = menu.children.find(item => {
              return path.indexOf(item.url) != -1;
            });
            if (children) {
              key = children.key;
              keyPath.push(children.key);
            }
          }
          if (key != this.activeIndex) {
            this.handleSelect(key, keyPath);
          }
        }
      },
      deep: true
    }
  },
  mounted() {
    let path = this.$route.path;
    let key = "",
      keyPath = [];
    let menus = this.menus;
    let menu = menus.find(item => {
      return path.indexOf(item.url) != -1;
    });
    if (menu) {
      key = menu.key;
      keyPath.push(menu.key);
      if (menu.children && menu.children.length > 0) {
        let children = menu.children.find(item => {
          return path.indexOf(item.url) != -1;
        });
        if (children) {
          key = children.key;
          keyPath.push(children.key);
        }
      }
      this.handleSelect(key, keyPath);
    }
  },
  methods: {
    handleSelect(key, keyPath) {
      this.activeIndex = key;
      let menus = this.menus;
      let path = keyPath[0];
      let menu = menus.find(item => {
        return item.key === path;
      });
      let active = {
        url: menu.url,
        key: menu.key,
        title: menu.title
      };
      if (keyPath.length > 1) {
        path = keyPath[1];
        let children = menu.children.find(item => {
          return item.key === path;
        });
        active.childrenTitle = children.title;
        active.url = children.url;
      }
      this.active = active;
      if (this.$route.path.indexOf(active.url) == -1) {
        this.$router.push(active.url);
      }

      this.$emit("select", active);
    }
  }
};
</script>

<style lang="less">
@import "./index.less";
</style>
