<!--
 * @Description:
 * @Autor: Zhongyu
 * @Date: 2021-09-01 10:38:05
 * @LastEditors: Zhongyu
 * @LastEditTime: 2021-09-06 00:33:38
-->
<template>
  <div class="personal-center">
    <el-container class="personal-container">
      <el-aside width="160px">
        <div class="title">全部功能</div>
        <menus @select="handleSelect" :active="active"></menus>
      </el-aside>
      <el-container>
        <el-header style="height: 56px;">
          <span :class="{ gray: active.childrenTitle }">
            {{ active.title }}
          </span>
          <span v-if="active.childrenTitle">
            <span style="color: #8d909a;">/</span>{{ active.childrenTitle }}
          </span>
        </el-header>
        <el-main>
          <router-view />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import Menus from "./components/Menus";
export default {
  components: {
    Menus
  },
  data() {
    return {
      active: {
        key: "1",
        title: "个人信息",
        childrenTitle: "我的资料"
      }
    };
  },
  methods: {
    handleSelect(activeItem) {
      this.active = activeItem;
    }
  }
};
</script>

<style lang="less">
@import "./index.less";
// @import "./install.less";
</style>
